<template>
    <div class="wrapper-registro cover-login">
        <div class="popup -full">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Formulario de registro Alumni, Elumni & Friends</h2>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="nombre" placeholder="Nombre" v-model="data.name"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="apellido-uno" placeholder="Apellido 1"
                                v-model="data.surname" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="apellido-dos" placeholder="Apellido 2"
                                v-model="data.surname_2" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="nacionalidad" name="nacionalidad" v-model="data.nationality" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">Nacionalidad</option>
                                <option :key="key" v-for="(pais,key) in dominios.paises" name="myBrowser" :value="pais">
                                    {{pais}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="tipodocumento" name="tipodocumento" v-model="data.document_type" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">Tipo de documento</option>
                                <option :key="key" v-for="(tipo,key) in dominios.tiposdocumento" name="myBrowser" :value="key">
                                    {{tipo}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider :rules="(data.document_type != 1) ? 'required|validdni' : 'required'" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="dni" :placeholder="(data.document_type) ? dominios.tiposdocumento[data.document_type] : 'Documento'" v-model="data.document"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="email" name="mail" placeholder="Mail" v-model="data.email"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="movil" placeholder="Móvil" v-model="data.phone"
                                :class="classes">
                            <p class="msg-info-tel">Por favor no utilices ni espacios ni símbolos, si tienes un teléfono
                                extranjero indica el
                                prefijo con 00 en vez de con +</p>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half row-start fww">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <h5>Sexo</h5>
                            <input type="radio" :value="'0'" name="sexo" id="hombre" v-model="data.gender">
                            <label :class="classes" for="hombre">Hombre</label>
                            <input type="radio" :value="'1'" name="sexo" id="mujer" v-model="data.gender">
                            <label :class="classes" for="mujer">Mujer</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <datepicker :label="'Fecha de nacimiento'" v-model="data.birthday_date" :name="'fecha'"
                                :class="classes">
                            </datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="paisresidencia" name="paisresidencia" v-model="data.country" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">País de residencia</option>
                                <option :key="key" v-for="(pais,key) in dominios.paises" name="myBrowser" :value="pais">
                                    {{pais}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half" v-if="data.country != 'España'">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="provincia" placeholder="Provincia"
                                v-model="data.province" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half"  v-if="data.country == 'España'">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <select id="provincia" name="provincia" v-model="data.province" class="pretty"
                                :class="classes">
                                <option name="myBrowser" placeholder="" value="">Provincia</option>
                                <option :key="key" v-for="(provincia,key) in dominios.provincias" name="myBrowser" :value="provincia">
                                    {{provincia}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="direction" placeholder="Dirección"
                                v-model="data.address" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:5" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="cp" placeholder="Código Postal"
                                v-model="data.cp" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider :rules="'required|min:8'" v-slot="{ errors,ariaInput,ariaMsg,classes }"  vid="password">
                            <input autocomplete="off" type="password" name="pass" placeholder="Contraseña"
                                v-model="data.password" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-input half">
                        <ValidationProvider :rules="'required|confirmed:password|min:8'" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="password" name="repite-pass"
                                placeholder="Repite tu contraseña" v-model="data.password_confirmation"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="text_politica">
                        <p>El responsable del tratamiento de sus datos es la Universidad Francisco de Vitoria (UFV).</p>
                        <p>La finalidad del tratamiento es</p>
                        <div style="padding: 0 15px;">
                            <ul>
                                <li>Enviarle, por cualquier medio, incluidos los electrónicos (SMS, Whatsapp, correo
                                    electrónico y redes sociales), comunicaciones relacionadas con empleabilidad,
                                    observatorio de empleo, ofertas, promociones, productos, actividades, concursos y
                                    servicios de la Universidad Francisco de Vitoria y/o patrocinados por la misma.</li>
                                <li>Gestionar su inscripción en las actividades organizadas por la Universidad Francisco
                                    de Vitoria (UFV).</li>
                                <li>Publicar videos e imágenes en las diferentes páginas webs de la universidad y redes
                                    sociales en las que pueda aparecer a raíz de su participación en las diferentes
                                    actividades de la universidad (conferencias, concursos, etc.). En el caso de que se
                                    le invitara a participar en actuaciones específicas de promoción de la Universidad
                                    Francisco de Vitoria, se le pediría su consentimiento documentalmente si su imagen
                                    tuviera un carácter relevante.</li>
                            </ul>

                        </div>

                        <p>
                            La legitimación del tratamiento se basa en el consentimiento del interesado marcando la
                            casilla destinada a tal efecto, y el interés legítimo del responsable, según se detalla en
                            la información ampliada. Los datos se mantendrán mientras usted no revoque el consentimiento
                            otorgado o no se oponga al tratamiento realizado a través del link:<a
                                href="https://www.alumni-ufv.es/es/general/solicitar-baja"
                                target="_blank">https://www.alumni-ufv.es/es/general/solicitar-baja</a></p>
                        <p>La información facilitada no será comunicada a terceros ajenos a la UFV, salvo a prestadores
                            de servicio con acceso a datos. </p>
                        <p>Puede ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del
                            tratamiento y portabilidad mediante un escrito dirigido a la Secretaría General de la
                            Universidad Francisco de Vitoria, Ctra. M-515 Pozuelo-Majadahonda Km. 1,800; 28223, Pozuelo
                            de Alarcón (Madrid), o al correo electrónico <a href="mailto:dpd@ufv.es.">dpd@ufv.es.</a>
                        </p>
                        <p>
                            Si a través del presente formulario facilita datos de terceras personas, le informamos de
                            que queda obligado a informar al interesado sobre el contenido de esta cláusula.</p>

                        <p>
                            Puede consultar la información ampliada en la web <a
                                href="https://www.alumni-ufv.es/es/general/politica-privacidad"
                                target="_blank">https://www.alumni-ufv.es/es/general/politica-privacidad</a>
                        </p>


                    </div>
                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input type="checkbox" id="contact" v-model="data.contact" :class="classes">
                            <label for="contact">Acepto y autorizo a que mis datos sean tratados por la Universidad Francisco
                                de
                                Vitoria, con las finalidades indicadas anteriormente y para remitirme, por cualquier
                                medio,
                                incluidos los electrónicos (SMS, Whatsapp, correo electrónico y redes sociales) las
                                comunicaciones correspondientes.</label>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Enviar</button>
                    </div>


                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapGetters,mapActions } from 'vuex';

    import router from '@/router';

    export default {
        name: 'registro',
        data: () => ({
            data: {
                
                'document': '',
                'document_type': '',
                'email': '',
                'password': '',
                'password_confirmation': '',
                'name': '',
                'surname': '',
                'surname_2': '',
                'phone': '',
                'birthday_date': '',
                'gender': '',
                'nationality': '',
                'country': '',
                'province': '',
                'contact': '',
            },
            loading : false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['createAlumni']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.createAlumni(this.data).then(r => {
                            this.loading = false;
                            if(r){
                                router.push({
                                    name : 'login'
                                })
                            }
                        })
                    }
                });
            },
        }
    }
</script>